
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--md-space);
  text-align: left;
  position: relative;
}

.form-group input[type="text"],
.form-group input[type="password"]  {
  height: var(--xl-space);
  border-radius: var(--xs-space);
  border: 1px solid var(--main-dark);
  padding-left: var(--xs-space);
  width: 100%;
}

.form-group input[type="text"]::placeholder,
.form-group input[type="password"]::placeholder  {
font-size: .75rem;
}

.icon-group {
  position: relative;
}

.icon-group .icon {
  position: absolute;
  right: .5rem;
  top: .20rem;
  font-size: 1.25rem;
  color: var(--sembraCare-main);
  cursor: pointer;
}

.icon-disabled .icon {
  opacity: .2;
  cursor: default;
}

label {
cursor: pointer;
}

.checkbox-input div {
  margin: var(--md-space) 0;
}

input[type="checkbox"], input[type="radio"] {
  margin-right: var(--sm-space);
  cursor: pointer;
}

input[type="text"]:disabled, 
input[type="password"]:disabled {
opacity: .1;
}
