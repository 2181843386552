@import "../css/nav.css";


header {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  /* border-bottom: 3px solid var(--sembraCare-secondary); */
  box-shadow: 0px 2px 5px var(--box-shadow);
  width: 100%;
  padding: .25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (min-width: 992px) {
  header {
    justify-content: space-between;
  }
}

.content-wrapper {
  background: var(--background-main);
  min-height: 100vh;
  /* padding: 5rem 1rem; */

}

@media screen and (min-width: 992px) {
  .content-wrapper {
    max-width: 1024px;
    margin: auto;
    display: flex;
  }
}

main {
  line-height: 1.25;
  padding: 5rem 0;
}

section {
  padding: .5rem;
  box-shadow: 0 0 3px var(--box-shadow);
  max-width: 700px;
  margin: auto;
  margin-bottom: 2rem;
  background: white;
}

/* .container {
  max-width: 1024px;
  padding: 0 1.4rem;
  margin: 0 auto;
} */

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 3px solid #caa819;
  box-shadow: 2px 0px 5px var(--box-shadow);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: .25rem;
}

footer img {
  height: 35px;
  }

  @media screen and (min-width: 992px) {
    main {
      width: 75%;
  padding: 5rem .5rem;
    }
  }
