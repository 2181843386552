header h1 {
  font-size: calc(16px + 1.25vmin);
  padding: .5rem;
}

p {
  margin-bottom: 1rem;
}

h1, h2, h5, h6 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 500;
}

h1, h5, h6 {
  color: var(--heading-text-color);
}

h2 {
  background: var(--sembraCare-main);
  color: white;
  padding: .25rem;
  font-size: calc(20px + 1.25vmin);
  text-align: center;
  margin-bottom: 1rem;
}

h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: calc(12px + 1.15vmin);

  color: black;
  text-decoration: underline;
  font-weight: 600;
}

h4 {
  font-size: calc(10px + 1.15vmin);
  background: black;
  color: white;
  padding: var(--xs-space) var(--sm-space);
}




@media screen and (min-width: 992px) {
  h2{
    padding: .5rem;
  }
}
