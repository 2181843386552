

.login-content {
    background: whitesmoke;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: 45% 90%; */
    min-height: 100vh;
    display: flex;
    padding: var(--sm-space);
}

.login-content .form-wrapper {
    position: relative;
    width: 100%;
    max-width: 580px;
    margin: auto;
    /* background: rgba(0, 0, 0, 0.8); */
    /* color: white; */
    border-radius: 0.25rem;
    box-shadow: 0 0 1px white;
    padding: 0 0 1rem;
}

.login-content section {
  background: none;
}

.login-content form {
    padding: var(--sm-space);
    overflow-x: hidden;
}

.reload {
    margin-top: var(--md-space);
    display: block;
  }

.login-link {
    width: 100%;
    text-align: center;
  }

