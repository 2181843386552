@import "./assets/css/pages/login.css";
@import "./assets/css/pages/documents.css";
@import "./assets/css/pages/review-information.css";
@import "./assets/css/user-menu.css";

@import "./assets/css/layout.css";
@import "./assets/css/table.css";
@import "./assets/css/typograhy.css";
@import "./assets/css/buttons.css";
@import "./assets/css/common.css";
@import "./assets/css/forms.css";

:root {
  /* Brand Colors  */
  --sembraCare-main: #0000c2;
  --main-dark: #00005c;
  --sembraCare-secondary: #caa819;
  --secondary-light: #ecc526;
  --secondary-dark: #bb741e;

  /* App Colors  */
  --primary-text-color: #3b3b3b;
  --background-main: whitesmoke;
  --heading-text-color: var(--main-dark);
  --box-shadow: gray;

  /* Error Messsage Colors  */
  --err-text: #7a0000;
  --err-background: #f8c9c9;

  /* Success Message Colors */
  --success-text: #004400;
  --success-background: #ceffce;

  /* Information Message Colors  */
  --info-text: #10105e;
  --info-background: #c3f0ff;

  /* Spacing  */
  --xs-space: .25rem;
  --sm-space: .5rem;
  --md-space: 1rem;
  --lg-space: 1.5rem;
  --xl-space: 2rem;  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

body {
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--primary-text-color);
  font-size: calc(8px + .85vmin);
}



/* FRAMEWORK  */
.text-center {
  text-align: center;
}

/* margins  */
.mt-xl {
  margin-top: var(--xl-space);
}
.mb-md {
  margin-bottom: var(--md-space)
}
/* .mt-xs {
  margin-bottom: var(--xs-space);
}

.mr-sm {
  margin-right: var(--sm-space);
} */






