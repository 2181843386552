.user-menu {
  position: absolute;
  top: 3rem;
  right: 15px;
}

.user-menu-icon {
  color: var(--sembraCare-main);
  font-size: 2.25rem;
  cursor: pointer;
}

.user-menu .item {
  display: flex;
  align-items: center;
  margin-bottom: var(--sm-space);
  cursor: pointer;
  color: var(--primary-text-color);
}

.user-menu .item a {
  color: var(--primary-text-color);
}

.user-menu .item-icon {
  font-size: var(--md-space);
  margin-right: var(--md-space);
}