
.nav {
  visibility: hidden;
  display: none;
}

/* nav ul li {
  list-style: none;
  line-height: 2rem;
} */

nav ul li a {
  text-decoration: none;
  color: var(--primary-text-color);
}

nav ul li a:hover {
  text-decoration: underline;
  color: var(--sembraCare-secondary);
}

.navbar-item {
  display: flex;
  align-items: center;
}

.navbar-item a {
  text-decoration: none;
  color: var(--primary-text-color);
}

.navbar-item a.disabled {
  pointer-events: none;
    cursor: default;
    opacity: .6;
}

.navbar-item a.active {
text-decoration: underline;
}

.navbar-item a:hover {
  text-decoration: underline;
  color: var(--sembraCare-secondary);
}

.nav-icon {
  color: var(--sembraCare-main);
  margin-right: 1rem;
}

.collapsed-nav {
  background: var(--background-main);
  height: 100vh;
  width: 100vw;
  position: fixed;
  margin-top: 3.45rem;
  z-index: 2;
}

.collapsed-nav .navbar-links {
  background: white;
  margin-top: 2rem;
  padding: 2rem;
  line-height: 2rem;
  font-size: 1rem;
}

@media screen and (min-width: 992px) {
  #navbar-toggle,
  #navbar-toggle[aria-expanded='true'] {
    display: none;
  }

  nav {
    border-right: 1px solid var(--sembraCare-secondary);
    padding: 5rem .5rem;
  }

  .nav {
    visibility: visible;
    display: flex;
    /* width: 100%; */
    margin-top: 3rem;
    position: fixed;
  }

  .navbar {
    width: 25%;
  }
}

